import ErrorPage from '@/components/ErrorPage'
import Loading from '@/components/Loading'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import NotFoundPage from '@repo/ui/notFound'
// import { TanStackRouterDevtools } from '@tanstack/router-devtools'

export const Route = createRootRouteWithContext()({
	component: () => {
		return (
			<>
				<Outlet />
				{/*<TanStackRouterDevtools />*/}
			</>
		)
	},
	errorComponent: ({
		error,
		info,
		reset
	}) => <ErrorPage error={error} info={info} reset={reset} />,
	pendingComponent: () => <Loading fullScreen />,
	notFoundComponent: () => <NotFoundPage />
})
