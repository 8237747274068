const DynamicReleaseNotes = ( {releaseNotes} ) => {
  return (
    <article class="flex flex-col gap-8">
      {releaseNotes.map((rn) => {
        return (
          <>
            <span className="text-md">
              <em>{rn.rnDate}</em>
            </span>
            {rn.rnTitle && <h2 className="text-xl md:text-3xl font-bold">{rn.rnTitle}</h2>}
            {rn.rnContent.map((content) => {
              return (
                <p>{content}</p>
              )
            })}
            {rn.newsHeader && <h2 className="text-xl md:text-3xl font-bold">{rn.newsHeader}</h2>}
            {rn.news.map((news) => {
              return (
                <section class="flex flex-col gap-4">
                  <h2 className="text-xl md:text-2xl font-bold">{news.title}</h2>
                  <ul class="list-disc list-outside ml-4">
                    {news.content.map((content) => {
                      return (
                        <li>{content}</li>
                      )
                    })}
                  </ul>
                </section>
              )
            })}
          </>
        )
      })}
    </article>
  );
};

export default DynamicReleaseNotes;
