
import DynamicReleaseNotes from "@repo/ui/dynamicReleaseNotes";

const releaseNotes = [{
    rnDate: "December 10, 2024",
    rnTitle: "Introducing the Unified Admin Panel (UAP) 2.0!",
    rnContent: [
        "Welcome to our newly redesigned admin experience! We're excited to unveil the Unified Admin Panel (UAP) 2.0, your comprehensive command center for managing all Carde.io-powered games and tournaments.",
        "The UAP marks the transition to a new era for organized play management on the Carde.io network. Starting with tournament management, we're bringing all your administrative tools together in one streamlined interface – no more jumping between different dashboards or portals.",
        "Players will continue to use their familiar platforms like play.sorcerytcg.com and play.uvs.com, while tournament organizers and store owners can now access core tournament management features through the UAP.",
        "We've built this new version of the UAP with flexibility in mind. You'll find tournament management tools available now, with more features rolling out over the next year. Future additions will include analytics dashboards to help you track player engagement and tournament performance, among other capabilities we're excited to share soon.",
        "This launch marks the next step in our mission to simplify tournament management and empower organizers with powerful, intuitive tools. We're thrilled to have you with us as we continue to enhance and expand the Carde.io network."
    ],
    news: [{
        title: "New Features",
        content: [
            "Implemented event search functionality in URL parameters, allowing direct access to filtered event views and improved shareability",
            "Added new fields for Rounds and Top Cut to provide more detailed event configuration options before an event starts",
            "Added customizable number of events per page on the event list, along with improved pagination controls to enhance browsing experience",
        ]
    },
    {
        title: "Improvements",
        content: [
            "Implemented COPPA (Children's Online Privacy Protection Act) validation on the add new player form to ensure compliance and safety",
            "Significantly improved performance of the create event form through optimization",
            "Enhanced performance of game data management operations for smoother handling of large datasets",
            "Enhanced the create event form workflow by starting on later steps when possible",
        ]
    },
    {
        title: "Bug Fixes",
        content: [
            "Resolved issues with event share links to ensure consistent and reliable sharing functionality",
            "Fixed websockets event synchronization to provide real-time updates more reliably",
            "Corrected auto login redirection behavior to properly guide users to their intended destination",
            "Resolved issue where users were unable to edit Additional Information after event creation",
        ]
    }]
}]

const AdminReleaseNotes = () => {
    return (
        <main className="flex flex-col mx-auto gap-10 max-w-screen-xl min-h-screen px-6 py-24 sm:py-32 lg:px-8">
      <h1 className="text-xl md:text-3xl font-bold">
                CARDE.IO Release Notes
            </h1>
            <DynamicReleaseNotes releaseNotes={releaseNotes} />
        </main>
    )
}

export default AdminReleaseNotes;