export const iso4217 = {
  AED: { code: 784, decimals: 2, countries: 'United Arab Emirates' },
  AFN: { code: 971, decimals: 2, countries: 'Afghanistan' },
  ALL: { code: 8, decimals: 2, countries: 'Albania' },
  AMD: { code: 51, decimals: 2, countries: 'Armenia' },
  ANG: { code: 532, decimals: 2, countries: 'Curaçao (CW), Sint Maarten (SX)' },
  AOA: { code: 973, decimals: 2, countries: 'Angola' },
  ARS: { code: 32, decimals: 2, countries: 'Argentina' },
  AUD: {
    code: 36,
    decimals: 2,
    countries:
      'Australia, Christmas Island (CX), Cocos (Keeling) Islands (CC), Heard Island and McDonald Islands (HM), Kiribati (KI), Nauru (NR), Norfolk Island (NF), Tuvalu (TV)',
  },
  AWG: { code: 533, decimals: 2, countries: 'Aruba' },
  AZN: { code: 944, decimals: 2, countries: 'Azerbaijan' },
  BAM: { code: 977, decimals: 2, countries: 'Bosnia and Herzegovina' },
  BBD: { code: 52, decimals: 2, countries: 'Barbados' },
  BDT: { code: 50, decimals: 2, countries: 'Bangladesh' },
  BGN: { code: 975, decimals: 2, countries: 'Bulgaria' },
  BHD: { code: 48, decimals: 3, countries: 'Bahrain' },
  BIF: { code: 108, decimals: 0, countries: 'Burundi' },
  BMD: { code: 60, decimals: 2, countries: 'Bermuda' },
  BND: { code: 96, decimals: 2, countries: 'Brunei' },
  BOB: { code: 68, decimals: 2, countries: 'Bolivia' },
  BOV: { code: 984, decimals: 2, countries: 'Bolivia' },
  BRL: { code: 986, decimals: 2, countries: 'Brazil' },
  BSD: { code: 44, decimals: 2, countries: 'Bahamas' },
  BTN: { code: 64, decimals: 2, countries: 'Bhutan' },
  BWP: { code: 72, decimals: 2, countries: 'Botswana' },
  BYN: { code: 933, decimals: 2, countries: 'Belarus' },
  BZD: { code: 84, decimals: 2, countries: 'Belize' },
  CAD: { code: 124, decimals: 2, countries: 'Canada' },
  CDF: { code: 976, decimals: 2, countries: 'Democratic Republic of the Congo' },
  CHE: { code: 947, decimals: 2, countries: 'Switzerland' },
  CHF: { code: 756, decimals: 2, countries: 'Switzerland, Liechtenstein' },
  CHW: { code: 948, decimals: 2, countries: 'Switzerland' },
  CLF: { code: 990, decimals: 4, countries: 'Chile' },
  CLP: { code: 152, decimals: 0, countries: 'Chile' },
  CNY: { code: 156, decimals: 2, countries: 'China' },
  COP: { code: 170, decimals: 2, countries: 'Colombia' },
  COU: { code: 970, decimals: 2, countries: 'Colombia' },
  CRC: { code: 188, decimals: 2, countries: 'Costa Rica' },
  CUP: { code: 192, decimals: 2, countries: 'Cuba' },
  CVE: { code: 132, decimals: 2, countries: 'Cabo Verde' },
  CZK: { code: 203, decimals: 2, countries: 'Czech Republic' },
  DJF: { code: 262, decimals: 0, countries: 'Djibouti' },
  DKK: { code: 208, decimals: 2, countries: 'Denmark, Faroe Islands, Greenland' },
  DOP: { code: 214, decimals: 2, countries: 'Dominican Republic' },
  DZD: { code: 12, decimals: 2, countries: 'Algeria' },
  EGP: { code: 818, decimals: 2, countries: 'Egypt' },
  ERN: { code: 232, decimals: 2, countries: 'Eritrea' },
  ETB: { code: 230, decimals: 2, countries: 'Ethiopia' },
  EUR: { code: 978, decimals: 2, countries: 'Eurozone' },
  FJD: { code: 242, decimals: 2, countries: 'Fiji' },
  FKP: { code: 238, decimals: 2, countries: 'Falkland Islands' },
  GBP: { code: 826, decimals: 2, countries: 'United Kingdom' },
  GEL: { code: 981, decimals: 2, countries: 'Georgia' },
  GHS: { code: 936, decimals: 2, countries: 'Ghana' },
  GIP: { code: 292, decimals: 2, countries: 'Gibraltar' },
  GMD: { code: 270, decimals: 2, countries: 'Gambia' },
  GNF: { code: 324, decimals: 0, countries: 'Guinea' },
  GTQ: { code: 320, decimals: 2, countries: 'Guatemala' },
  GYD: { code: 328, decimals: 2, countries: 'Guyana' },
  HKD: { code: 344, decimals: 2, countries: 'Hong Kong' },
  HNL: { code: 340, decimals: 2, countries: 'Honduras' },
  HTG: { code: 332, decimals: 2, countries: 'Haiti' },
  HUF: { code: 348, decimals: 2, countries: 'Hungary' },
  IDR: { code: 360, decimals: 2, countries: 'Indonesia' },
  ILS: { code: 376, decimals: 2, countries: 'Israel' },
  INR: { code: 356, decimals: 2, countries: 'India, Bhutan' },
  IQD: { code: 368, decimals: 3, countries: 'Iraq' },
  IRR: { code: 364, decimals: 2, countries: 'Iran' },
  ISK: { code: 352, decimals: 0, countries: 'Iceland' },
  JMD: { code: 388, decimals: 2, countries: 'Jamaica' },
  JOD: { code: 400, decimals: 3, countries: 'Jordan' },
  JPY: { code: 392, decimals: 0, countries: 'Japan' },
  KES: { code: 404, decimals: 2, countries: 'Kenya' },
  KGS: { code: 417, decimals: 2, countries: 'Kyrgyzstan' },
  KHR: { code: 116, decimals: 2, countries: 'Cambodia' },
  KMF: { code: 174, decimals: 0, countries: 'Comoros' },
  KPW: { code: 408, decimals: 2, countries: 'North Korea' },
  KRW: { code: 410, decimals: 0, countries: 'South Korea' },
  KWD: { code: 414, decimals: 3, countries: 'Kuwait' },
  KYD: { code: 136, decimals: 2, countries: 'Cayman Islands' },
  KZT: { code: 398, decimals: 2, countries: 'Kazakhstan' },
  LAK: { code: 418, decimals: 2, countries: 'Laos' },
  LBP: { code: 422, decimals: 2, countries: 'Lebanon' },
  LKR: { code: 144, decimals: 2, countries: 'Sri Lanka' },
  LRD: { code: 430, decimals: 2, countries: 'Liberia' },
  LSL: { code: 426, decimals: 2, countries: 'Lesotho' },
  LYD: { code: 434, decimals: 3, countries: 'Libya' },
  MAD: { code: 504, decimals: 2, countries: 'Morocco, Western Sahara' },
  MDL: { code: 498, decimals: 2, countries: 'Moldova' },
  MGA: { code: 969, decimals: 2, countries: 'Madagascar' },
  MKD: { code: 807, decimals: 2, countries: 'North Macedonia' },
  MMK: { code: 104, decimals: 2, countries: 'Myanmar' },
  MNT: { code: 496, decimals: 2, countries: 'Mongolia' },
  MOP: { code: 446, decimals: 2, countries: 'Macau' },
  MRU: { code: 929, decimals: 2, countries: 'Mauritania' },
  MUR: { code: 480, decimals: 2, countries: 'Mauritius' },
  MVR: { code: 462, decimals: 2, countries: 'Maldives' },
  MWK: { code: 454, decimals: 2, countries: 'Malawi' },
  MXN: { code: 484, decimals: 2, countries: 'Mexico' },
  MXV: { code: 979, decimals: 2, countries: 'Mexico' },
  MYR: { code: 458, decimals: 2, countries: 'Malaysia' },
  MZN: { code: 943, decimals: 2, countries: 'Mozambique' },
  NAD: { code: 516, decimals: 2, countries: 'Namibia' },
  NGN: { code: 566, decimals: 2, countries: 'Nigeria' },
  NIO: { code: 558, decimals: 2, countries: 'Nicaragua' },
  NOK: { code: 578, decimals: 2, countries: 'Norway, Svalbard and Jan Mayen, Bouvet Island' },
  NPR: { code: 524, decimals: 2, countries: 'Nepal' },
  NZD: { code: 554, decimals: 2, countries: 'New Zealand, Cook Islands, Niue, Pitcairn Islands, Tokelau' },
  OMR: { code: 512, decimals: 3, countries: 'Oman' },
  PAB: { code: 590, decimals: 2, countries: 'Panama' },
  PEN: { code: 604, decimals: 2, countries: 'Peru' },
  PGK: { code: 598, decimals: 2, countries: 'Papua New Guinea' },
  PHP: { code: 608, decimals: 2, countries: 'Philippines' },
  PKR: { code: 586, decimals: 2, countries: 'Pakistan' },
  PLN: { code: 985, decimals: 2, countries: 'Poland' },
  PYG: { code: 600, decimals: 0, countries: 'Paraguay' },
  QAR: { code: 634, decimals: 2, countries: 'Qatar' },
  RON: { code: 946, decimals: 2, countries: 'Romania' },
  RSD: { code: 941, decimals: 2, countries: 'Serbia' },
  RUB: { code: 643, decimals: 2, countries: 'Russia' },
  RWF: { code: 646, decimals: 0, countries: 'Rwanda' },
  SAR: { code: 682, decimals: 2, countries: 'Saudi Arabia' },
  SBD: { code: 90, decimals: 2, countries: 'Solomon Islands' },
  SCR: { code: 690, decimals: 2, countries: 'Seychelles' },
  SDG: { code: 938, decimals: 2, countries: 'Sudan' },
  SEK: { code: 752, decimals: 2, countries: 'Sweden' },
  SGD: { code: 702, decimals: 2, countries: 'Singapore' },
  SHP: { code: 654, decimals: 2, countries: 'Saint Helena, Ascension Island' },
  SLE: { code: 925, decimals: 2, countries: 'Sierra Leone' },
  SLL: { code: 694, decimals: 2, countries: 'Sierra Leone' },
  SOS: { code: 706, decimals: 2, countries: 'Somalia' },
  SRD: { code: 968, decimals: 2, countries: 'Suriname' },
  SSP: { code: 728, decimals: 2, countries: 'South Sudan' },
  STN: { code: 930, decimals: 2, countries: 'São Tomé and Príncipe' },
  SVC: { code: 222, decimals: 2, countries: 'El Salvador' },
  SYP: { code: 760, decimals: 2, countries: 'Syria' },
  SZL: { code: 748, decimals: 2, countries: 'Eswatini' },
  THB: { code: 764, decimals: 2, countries: 'Thailand' },
  TJS: { code: 972, decimals: 2, countries: 'Tajikistan' },
  TMT: { code: 934, decimals: 2, countries: 'Turkmenistan' },
  TND: { code: 788, decimals: 3, countries: 'Tunisia' },
  TOP: { code: 776, decimals: 2, countries: 'Tonga' },
  TRY: { code: 949, decimals: 2, countries: 'Turkey' },
  TTD: { code: 780, decimals: 2, countries: 'Trinidad and Tobago' },
  TWD: { code: 901, decimals: 2, countries: 'Taiwan' },
  TZS: { code: 834, decimals: 2, countries: 'Tanzania' },
  UAH: { code: 980, decimals: 2, countries: 'Ukraine' },
  UGX: { code: 800, decimals: 0, countries: 'Uganda' },
  USD: { code: 840, decimals: 2, countries: 'United States, etc.' },
  USN: { code: 997, decimals: 2, countries: 'United States' },
  UYI: { code: 940, decimals: 0, countries: 'Uruguay' },
  UYU: { code: 858, decimals: 2, countries: 'Uruguay' },
  UYW: { code: 927, decimals: 4, countries: 'Uruguay' },
  UZS: { code: 860, decimals: 2, countries: 'Uzbekistan' },
  VED: { code: 926, decimals: 2, countries: 'Venezuela' },
  VES: { code: 928, decimals: 2, countries: 'Venezuela' },
  VND: { code: 704, decimals: 0, countries: 'Vietnam' },
  VUV: { code: 548, decimals: 0, countries: 'Vanuatu' },
  WST: { code: 882, decimals: 2, countries: 'Samoa' },
  XAF: { code: 950, decimals: 0, countries: 'CFA franc BEAC countries' },
  XAG: { code: 961, decimals: '.', countries: 'Silver (one troy ounce)' },
  XAU: { code: 959, decimals: '.', countries: 'Gold (one troy ounce)' },
  XBA: { code: 955, decimals: '.', countries: 'European Composite Unit' },
  XBB: { code: 956, decimals: '.', countries: 'European Monetary Unit' },
  XBC: { code: 957, decimals: '.', countries: 'European Unit of Account 9' },
  XBD: { code: 958, decimals: '.', countries: 'European Unit of Account 17' },
  XCD: { code: 951, decimals: 2, countries: 'East Caribbean dollar countries' },
  XDR: { code: 960, decimals: '.', countries: 'Special drawing rights' },
  XOF: { code: 952, decimals: 0, countries: 'CFA franc BCEAO countries' },
  XPD: { code: 964, decimals: '.', countries: 'Palladium (one troy ounce)' },
  XPF: { code: 953, decimals: 0, countries: 'CFP franc countries' },
  XPT: { code: 962, decimals: '.', countries: 'Platinum (one troy ounce)' },
  XSU: { code: 994, decimals: '.', countries: 'SUCRE' },
  XTS: { code: 963, decimals: '.', countries: 'Code reserved for testing' },
  XUA: { code: 965, decimals: '.', countries: 'ADB Unit of Account' },
  XXX: { code: 999, decimals: '.', countries: 'No currency' },
  YER: { code: 886, decimals: 2, countries: 'Yemen' },
  ZAR: { code: 710, decimals: 2, countries: 'South Africa and others' },
  ZMW: { code: 967, decimals: 2, countries: 'Zambia' },
  ZWL: { code: 932, decimals: 2, countries: 'Zimbabwe' },
};