import { Button } from "../Button";
import Typography from "../Typography";

const NotFoundPage = ({ Link = () => null }) => {
  return (
    <main className="grid min-h-screen place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-pink-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-white-900 sm:text-5xl">Page Not Found</h1>
        <p className="mt-6 text-base leading-7 text-white-600">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button className='px-8' variant='gradient-contained'>
            <a href="/">
              Go Back Home
            </a>
          </Button>

          <Link href="/contact" className="text-sm font-semibold text-primary">
            <Typography color='gradient'>
              Contact Us <span aria-hidden="true">&rarr;</span>
            </Typography>
          </Link>
        </div>
      </div>
    </main>
  )
}

export default NotFoundPage;
